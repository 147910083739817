import AttachmentLayoutFactory from "components/attachments/layouts/factory";
import { IAttachmentLayoutFactory } from "components/attachments/layouts/iFactory";
import { wccModules } from "enums/wccModules";
import FineUploaderFactory from "factories/fineUploader/s3/fineUploader";
import { IFineUploaderFactory } from "factories/fineUploader/s3/iFineUploader";
import VideoPlayerComponentFactory from "factories/videoPlayerComponent";
import { IVideoPlayerComponentFactory } from "factories/videoPlayerComponent.interfaces";
import WordsMapFactory from "factories/wordsMapFactory";
import { IWordsMapFactory } from "factories/wordsMapFactory.interfaces";
import { Func } from "interfaces/func";
import { Container, interfaces } from "inversify";
import AttachmentDuplicator from "managers/attachments/duplicator";
import { IAttachmentDuplicator } from "managers/attachments/iDuplicator";
import { GoogleRecaptchaV3Manager, ICaptchaManager } from "managers/captchas";
import CommunityTemplateTopicAvailableWidgetVariablesLoader from "managers/community/templates/topic/fields/available";
import ICommunityTemplateTopicAvailableWidgetVariablesLoader from "managers/community/templates/topic/fields/iAvailable";
import ICommunityTemplateTopicWidgetVariableValueLoader from "managers/community/templates/topic/fields/iValue";
import CommunityTemplateTopicWidgetVariableValueLoader from "managers/community/templates/topic/fields/value";
import { ICommunityTemplateTopicsGroupTopicLoader } from "managers/community/templates/topicsGroup/iTopic";
import CommunityTemplateTopicsGroupTopicLoader from "managers/community/templates/topicsGroup/topic";
import { IWCCStorageManager } from "managers/iStorage";
import overlayManager from "managers/overlay";
import { IOverlayManager, overlayManagerKey } from "managers/overlay.interfaces";
import SignalRCoreConnectionManager from "managers/signalR/core/connection";
import SignalRCoreEventsManager from "managers/signalR/core/events";
import { ISignalRCoreConnectionManager } from "managers/signalR/core/interfaces/connection";
import { ISignalRCoreEventsManager } from "managers/signalR/core/interfaces/events";
import { ISignalRCoreManager } from "managers/signalR/core/interfaces/main";
import { ISignalRCoreMessagesManager } from "managers/signalR/core/interfaces/messages";
import { ISignalRCoreModulesManager } from "managers/signalR/core/interfaces/modules";
import { ISignalRCoreSubscriptionsManager } from "managers/signalR/core/interfaces/subscriptions";
import { ISignalRCoreTokenManager } from "managers/signalR/core/interfaces/token";
import SignalRCoreManager from "managers/signalR/core/main";
import SignalRCoreMessagesManager from "managers/signalR/core/messages";
import SignalRCoreModulesManager from "managers/signalR/core/modules";
import SignalRCoreSubscriptionsManager from "managers/signalR/core/subscriptions";
import SignalRCoreTokenManager from "managers/signalR/core/token";
import SignalREventsManager from "managers/signalR/events";
import SignalRMessagesManager from "managers/signalR/messages";
import SignalRCurrentUserSessionManager from "managers/signalR/sessions/currentUser";
import { ISignalRCurrentUserSessionManager } from "managers/signalR/sessions/currentUser.interfaces";
import { WCCStorageManager } from "managers/storage";
import UserFilesDuplicator from "managers/userfiles/duplicator";
import { IUserFilesDuplicator } from "managers/userfiles/iDuplicator";
import { EffectsContainer, withEffects } from "mixins/withEffects";
import { ActivitiesService } from "services/activities";
import { AttachmentsService } from "services/attachments";
import { IAttachmentsService } from "services/attachments.interfaces";
import { AttributesDataService } from "services/attributesData";
import { CommentsService } from "services/comments";
import { CommunitiesService } from "services/communities";
import { ContactProfilerService } from "services/contactProfiler";
import { DiscussionService } from "services/discussion";
import { EmailsService } from "services/emails";
import { ICommunitiesService } from "services/iCommunities";
import InitialEndpointDataService from "services/initialEndpointData";
import { IPeopleService } from "services/iPeople";
import { ISignalRService } from "services/iSignalR";
import { LoginService } from "services/login";
import { OpenTokService } from "services/openTok";
import { PeopleService } from "services/people";
import { PixabayService } from "services/pixabay";
import { SignalRService } from "services/signalR";
import { StatisticsService } from "services/statistics";
import { SurveysService } from "services/surveys";
import { SystemService } from "services/system";
import { TagsService } from "services/tags";
import { TasksService } from "services/tasks";
import TimezoneService from "services/timezones";
import { ITimezoneService } from "services/timezones.interfaces";
import TopicCompletionActivitiesService from "services/topicCompletionActivities";
import { ITopicCompletionActivitiesService } from "services/topicCompletionActivities.interfaces";
import { TopicsService } from "services/topics";
import { UnsplashService } from "services/unsplash";
import { UserFilesService } from "services/userfiles";
import WebSitesService from "services/websites";
import { AccountService } from "../../../services/account";
import { ClientsService } from "../../../services/clients";
import { TwoFactorAuthService } from "../../../services/twofactorauth";
import { ServicePipelinesService } from "../../../services/servicePipelines";
import { InversifyHelpers, ModuleDefinition, ScopedModuleDefinition } from "./helpers";

new Array<ModuleDefinition>(
    container => container.bind<IUserFilesDuplicator>(wccModules.userFilesDuplicator).to(UserFilesDuplicator),
    container => container.bind<IAttachmentDuplicator>(wccModules.attachmentDuplicator).to(AttachmentDuplicator),
    container => container.bind<IFineUploaderFactory>(wccModules.fineUploaderFactory).to(FineUploaderFactory),
    container => container.bind<IAttachmentLayoutFactory>(wccModules.attachmentLayoutFactory).to(AttachmentLayoutFactory),
    container => container.bind<IWordsMapFactory>(wccModules.wordsMapFactory).to(WordsMapFactory),

    container => container.bind<ISignalRCoreManager>(wccModules.signalRManager).to(SignalRCoreManager),
    container => container.bind<ISignalRCoreTokenManager>(wccModules.signalRTokenManager).to(SignalRCoreTokenManager),
    container => container.bind<ISignalRCoreConnectionManager>(wccModules.signalRConnectionManager).to(SignalRCoreConnectionManager),
    container => container.bind<ISignalRCoreMessagesManager>(wccModules.signalRMessagesManager).to(SignalRCoreMessagesManager),
    container => container.bind<ISignalRCoreSubscriptionsManager>(wccModules.signalRSubscriptionsManager).to(SignalRCoreSubscriptionsManager),
    container => container.bind<ISignalRCoreEventsManager>(wccModules.signalREventsManager).to(SignalRCoreEventsManager),
    container => container.bind<ISignalRCoreModulesManager>(wccModules.signalRModulesManager).to(SignalRCoreModulesManager),

    container => container.bind<ISignalRCurrentUserSessionManager>(wccModules.signalRCurrentUserSession).to(SignalRCurrentUserSessionManager)
).forEach(definition => InversifyHelpers.registerSingletonInjectable(definition));

new Array<ScopedModuleDefinition>(
    container => container.bind<Container>(wccModules.container).toConstantValue(container),

    container => container.bind<interfaces.Factory<[Container, Action]>>(wccModules.scopeFactory).toFactory(context => () => {
        const container = context.container;
        const effects = container.get<EffectsContainer>(wccModules.effects);

        const scope = InversifyHelpers.createScope();
        effects.register(scope[1]);

        return scope;
    }),

    container => container.bind<IOverlayManager>(overlayManagerKey).toConstantValue(overlayManager),

    container => container.bind<EffectsContainer>(wccModules.effects).toDynamicValue(() => withEffects()),
    container => container.bind<IWCCStorageManager>(wccModules.storage).toDynamicValue(() => new WCCStorageManager()),

    container => container.bind<ICommunityTemplateTopicAvailableWidgetVariablesLoader>(wccModules.communityTemplateTopicAvailableWidgetVariablesLoader).to(CommunityTemplateTopicAvailableWidgetVariablesLoader),
    container => container.bind<ICommunityTemplateTopicsGroupTopicLoader>(wccModules.communityTemplateTopicsGroupTopicLoader).to(CommunityTemplateTopicsGroupTopicLoader),
    container => container.bind<ICommunityTemplateTopicWidgetVariableValueLoader>(wccModules.communityTemplateTopicWidgetVariableValueLoader).to(CommunityTemplateTopicWidgetVariableValueLoader),
    container => container.bind<ICaptchaManager>(wccModules.captchaManager).to(GoogleRecaptchaV3Manager),
    
    container => container.bind<IVideoPlayerComponentFactory>(wccModules.videoPlayerComponentFactory).to(VideoPlayerComponentFactory),

    container => container.bind<SignalREventsManager>(wccModules.signalREvents).to(SignalREventsManager),
    container => container.bind<SignalRMessagesManager>(wccModules.signalRMessages).to(SignalRMessagesManager),

    container => container.bind<AccountService>(wccModules.accountService).toDynamicValue(() => new AccountService()),
    container => container.bind<IAttachmentsService>(wccModules.attachmentsService).toDynamicValue(() => new AttachmentsService()),    
    container => container.bind<ICommunitiesService>(wccModules.communitiesService).toDynamicValue(() => new CommunitiesService()),
    container => container.bind<IPeopleService>(wccModules.peopleService).toDynamicValue(() => new PeopleService()),
    container => container.bind<ISignalRService>(wccModules.signalRService).to(SignalRService),
    container => container.bind<ITopicCompletionActivitiesService>(wccModules.topicCompletionActivitiesService).to(TopicCompletionActivitiesService),
    container => container.bind<ITimezoneService>(wccModules.timezoneService).to(TimezoneService),
    container => container.bind<ActivitiesService>(wccModules.activitiesService).toDynamicValue(() => new ActivitiesService()),
    container => container.bind<AttributesDataService>(wccModules.attributesDataService).toDynamicValue(() => new AttributesDataService()),
    container => container.bind<ClientsService>(wccModules.clientsService).toDynamicValue(() => new ClientsService()),
    container => container.bind<CommentsService>(wccModules.commentsService).toDynamicValue(() => new CommentsService()),
    container => container.bind<ContactProfilerService>(wccModules.contactProfilerService).toDynamicValue(() => new ContactProfilerService()),
    container => container.bind<DiscussionService>(wccModules.discussionService).toDynamicValue(() => new DiscussionService()),
    container => container.bind<EmailsService>(wccModules.emailsService).toDynamicValue(() => new EmailsService()),
    container => container.bind<LoginService>(wccModules.loginService).toDynamicValue(() => new LoginService()),
    container => container.bind<InitialEndpointDataService>(wccModules.initialEndpointDataService).toDynamicValue(() => new InitialEndpointDataService()),
    container => container.bind<OpenTokService>(wccModules.openTokService).to(OpenTokService),
    container => container.bind<PixabayService>(wccModules.pixabayService).toDynamicValue(() => new PixabayService()),
    container => container.bind<StatisticsService>(wccModules.statisticsService).toDynamicValue(() => new StatisticsService()),
    container => container.bind<ServicePipelinesService>(wccModules.servicePipelinesService).toDynamicValue(() => new ServicePipelinesService()),
    container => container.bind<SurveysService>(wccModules.surveysService).toDynamicValue(() => new SurveysService()),
    container => container.bind<SystemService>(wccModules.systemService).toDynamicValue(() => new SystemService()),
    container => container.bind<TagsService>(wccModules.tagsService).toDynamicValue(() => new TagsService()),
    container => container.bind<TasksService>(wccModules.tasksService).toDynamicValue(() => new TasksService()),
    container => container.bind<TopicsService>(wccModules.topicsService).toDynamicValue(() => new TopicsService()),
    container => container.bind<TwoFactorAuthService>(wccModules.twoFactorAuthService).toDynamicValue(() => new TwoFactorAuthService()),
    container => container.bind<UnsplashService>(wccModules.unsplashService).toDynamicValue(() => new UnsplashService()),
    container => container.bind<UserFilesService>(wccModules.userFilesService).toDynamicValue(() => new UserFilesService()),
    container => container.bind<WebSitesService>(wccModules.websitesService).toDynamicValue(() => new WebSitesService())
).forEach(definition => InversifyHelpers.registerInjectable(definition));

export function createContainer(ignore?: Func<boolean, [module: any]>, definitions: Array<ScopedModuleDefinition> = []): [Container, Action] {
    return InversifyHelpers.createScope(definitions, ignore);
}

export function createContainerUsingEffects(effects: EffectsContainer, ignore?: Func<boolean, [module: any]>, definitions: Array<ScopedModuleDefinition> = []) {
    const [container, disposeContainer] = createContainer(ignore, definitions);
    effects.register(() => () => disposeContainer());

    return container;
}

export const appContainer = createContainer()[0];