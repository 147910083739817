import storageDefaults from 'decorators/storageDefaults';
import { wccModules } from 'enums/wccModules';
import { Disposable } from 'interfaces/disposable';
import { inject, injectable } from 'inversify';
import { Subscribable } from 'knockout';
import { CurrentUserManager } from 'managers/currentUser';
import { IWCCStorageManager } from 'managers/iStorage';
import SignalREventsManager from 'managers/signalR/events';
import { EffectsContainer } from 'mixins/withEffects';
import { SimpleTopic } from 'models/simpleTopic';
import { ServicesContext } from 'services/context';
import { ISignalRCurrentUserSessionManager } from '../signalR/sessions/currentUser.interfaces';
import SimpleTopicsManager from './simpleTopics';

const { discussionId } = settings;

export interface DiscussionNotificationsManagerConfig {
    discussionId: string
}

function isAllowedTopic(topic: SimpleTopic) {
    if (topic.isIdeation())
        return false;

    return true;
}

@injectable()
@storageDefaults(<Partial<DiscussionNotificationsManagerConfig>>{ discussionId: discussionId })
export default class DiscussionNotificationsManager {
    private topics: Subscribable<Array<SimpleTopic>>
    private topicSubscriptions: Map<string, Disposable> = new Map();

    constructor(
        @inject(wccModules.managerConfig) { discussionId }: DiscussionNotificationsManagerConfig,
        @inject(wccModules.signalRCurrentUserSession) private currentUserSession: ISignalRCurrentUserSessionManager,
        @inject(wccModules.storage) storage: IWCCStorageManager,
        @inject(wccModules.servicesContext) ctx: ServicesContext,
        @inject(wccModules.signalREvents) private signalREvents: SignalREventsManager,
        @inject(wccModules.effects) effects: EffectsContainer
    ) {
        const userManager = storage.get(CurrentUserManager, { discussionId });
        const user = userManager.pluck('person');
        const userId = user.pluck('personId');
        const isModerator = user.pluck('isModerator', false);

        const topicsManager = storage.get(SimpleTopicsManager, { discussionId });
        const topics = topicsManager.pluck('topics', []);
        this.topics = topics.filter(topic => isAllowedTopic(topic));

        effects.register(userId => {
            if (userId != undefined)
                return signalREvents.personNotifications(userId).onNotificationEvent(this.onNewEvent.bind(this));
        }, [userId]);

        effects.register((userId, isModerator) => {
            if (userId != undefined && isModerator) {
                this.topics().forEach(topic => this.subscribeToTopicNotifications(topic));

                return this.topics.onArrayChange({
                    add: topic => this.subscribeToTopicNotifications(topic),
                    remove: topic => this.unsubscribeFromTopicNotifications(topic)
                });
            }
        }, [userId, isModerator]);        
    }    

    private onNewEvent(topicId: string, threadId: string, personId: string, eventType: number) {
        if (topicId != this.currentUserSession.topicId()) {
            const topic = this.topics().find(topic => topic.id() === topicId);

            if (topic != undefined) {
                const discussionId = topic.discussionId() ?? '';
                const topicId = topic.id() ?? '';
                const title = topic.title() ?? '';

                let message = this.getMessage(eventType);

                if (message != undefined) {
                    message += ' ' + messages.InTheTopic + ' "<a href="#topic/' + discussionId + '/' + topicId + '/unread">' + title + '</a>"';
                    system.showMessage(message);
                }
            }
        }
    }

    private getMessage(eventType: number) {
        switch (eventType) {
            case enums.NotificationEventType.NewCommentToRead.value: return messages.YouHaveANewCommentToRead;
            case enums.NotificationEventType.NewReplyToRead.value: return messages.YouHaveANewReplyToRead;
            case enums.NotificationEventType.NewReplyOnMyThread.value: return messages.SomeoneRepliedToYourComment;
            case enums.NotificationEventType.NeedsModeration.value:
            case enums.NotificationEventType.ReportedAsAbuse.value: return messages.YouHaveANewCommentToModerate;
            case enums.NotificationEventType.NewReplyOnFollowedThread.value: return messages.SomeoneRepliedToACommentYouAreFollowing;
        }
    }

    private subscribeToTopicNotifications(topic: SimpleTopic) {
        const topicId = topic.id();

        if (topicId != undefined)
            this.topicSubscriptions.set(topicId, this.signalREvents.topicNotifications(topicId).onNotificationEvent(this.onNewEvent.bind(this)));
    }

    private unsubscribeFromTopicNotifications(topic: SimpleTopic) {
        const topicId = topic.id();

        if (topicId != undefined) {
            this.topicSubscriptions.get(topicId)?.dispose();
            this.topicSubscriptions.delete(topicId);
        }
    }
}