import { wccModules } from "enums/wccModules";
import { inject, injectable } from "inversify";
import { Subscribable } from "knockout";
import { EffectsContainer } from "mixins/withEffects";
import { Disposable } from "../../../interfaces/disposable";
import { ISignalRCoreManager } from "../core/interfaces/main";
import SignalRMessagesManager from "../messages";
import { ISignalRCurrentUserSessionManager } from "./currentUser.interfaces";

@injectable()
export default class SignalRCurrentUserSessionManager implements ISignalRCurrentUserSessionManager {
    private topicIds = ko.observableArray<string>();
    topicId: Subscribable<string | undefined>

    constructor(
        @inject(wccModules.signalRManager) signalRManager: ISignalRCoreManager,
        @inject(wccModules.signalRMessages) private signalRMessages: SignalRMessagesManager,
        @inject(wccModules.effects) effects: EffectsContainer
    ) {
        this.topicId = this.topicIds.last();
    }

    assignToTopic(topicId: string): Disposable {
        this.topicIds.push(topicId);

        return {
            dispose: () => this.topicIds.remove(topicId)
        }
    }
}