import CommentsView from 'enums/comments/views';
import { Subscribable } from 'knockout';
import { IWCCStorageManager, StorageArg } from 'managers/iStorage';
import { WCCStorageManager } from 'managers/storage';
import { withEffects } from 'mixins/withEffects';
import TwoFactorAuthManager from './account/twoFactorAuthManager';
import CommunityAccessManager from './community/access';
import AdminCommunityPagesManager from './community/pages/admin';
import CommunityPageManager from './community/pages/page';
import PublicCommunityPagesManager from './community/pages/public';
import { CurrentUserManager } from './currentUser';
import DiscussionNotificationsManager from './discussion/notifications';
import DiscussionPeopleManager from './discussion/people/list';
import SimpleTopicsManager from './discussion/simpleTopics';
import DiscussionTermsManager from './discussion/terms';
import PersonManager from './people/person';
import SimpleTopicManager from './simpleTopic';
import SurveyStatisticsManager from './statistics/survey';
import PersonTagsManager from './tags/person';
import ThreadTagsManager from './tags/thread';
import TaskManager from './task';
import { TopicManager } from './topic';
import TopicAttachmentsManager, { AttachmentsView } from './topic/attachments';
import TopicAttachmentsUploadsManager from './topic/attachmentsUploads';
import CommentsManager from './topic/comments';
import { SurveyManager } from './topic/survey';

const { discussionId: rootDiscussionId } = settings;

export class ManagersContext {
    private effects = withEffects();
    private storage: IWCCStorageManager    

    private currentUserId: Subscribable<string | undefined>

    constructor() {
        this.storage = this.effects.register(new WCCStorageManager());        

        const currentUserManager = this.currentUser();
        const currentUser = currentUserManager.pluck('person');
        this.currentUserId = currentUser.pluck(p => p.personId);
    }

    accountImages(type: StorageArg<number>): Subscribable<any> {
        return this.storage.get(require('./account/images'), [type]);
    }

    activityStatistics(activityId: StorageArg<string>): Subscribable<any> {
        return this.storage.get(require('./statistics/activity'), [activityId]);
    }

    blogs(discussionId: StorageArg<string>): Subscribable<any> {
        return this.storage.get(require('./blogs'), [discussionId]);
    }

    communityAccess(communityId: StorageArg<string>) {
        return this.storage.get(CommunityAccessManager, { communityId });
    }

    communityAttachments(): Subscribable<any> {
        return this.storage.get(require('./community/attachments'));
    }

    communityPages(includeContent: StorageArg<boolean> = false, includeHistory: StorageArg<boolean> = false) {
        return this.storage.get(PublicCommunityPagesManager, { includeContent, includeHistory });
    }

    communityPagesAdmin(includeContent: StorageArg<boolean> = false, includeHistory: StorageArg<boolean> = false) {
        return this.storage.get(AdminCommunityPagesManager, { includeContent, includeHistory });
    }

    communityPageAdmin(pageId: StorageArg<string>, includeContent: StorageArg<boolean> = false, includeHistory: StorageArg<boolean> = false): Subscribable<any> {
        return this.storage.get(CommunityPageManager, { pageId, includeContent, includeHistory });
    }

    communityPageContent(pageId: StorageArg<string>) {
        return this.storage.get(require('./community/pages/content'), [pageId]);
    }

    communityPageStyles(pageId: StorageArg<string>): Subscribable<any> {
        return this.storage.get(require('./community/pages/styles'), [pageId]);
    }

    communityProjects(): Subscribable<any> {
        return this.storage.get(require('./community/projects'));
    }

    communitySurveys(): Subscribable<any> {
        return this.storage.get(require('./community/surveys'));
    }

    currentUser(discussionId: StorageArg<string> = rootDiscussionId) {
        return this.storage.get(CurrentUserManager, { discussionId });
    }

    currentUserTags() {
        return this.personTags(this.currentUserId);
    }

    discussionHighlights(discussionId: StorageArg<string> = rootDiscussionId): Subscribable<any> {
        return this.storage.get(require('./discussion/highlights'), [discussionId]);
    }

    discussionNotifications(discussionId: StorageArg<string> = rootDiscussionId) {
        return this.storage.get(DiscussionNotificationsManager, { discussionId });
    }

    discussionParticipants(discussionId: StorageArg<string> = rootDiscussionId) {
        return this.storage.get(DiscussionPeopleManager, { discussionId });
    }

    discussionPeople(discussionId: StorageArg<string> = rootDiscussionId) {
        return this.discussionParticipants(discussionId);
    }

    discussionStatistics(discussionId: StorageArg<string> = rootDiscussionId): Subscribable<any> {
        return this.storage.get(require('./statistics/discussion'), [discussionId]);
    }

    discussionTerms(discussionId: StorageArg<string> = rootDiscussionId) {
        return this.storage.get(DiscussionTermsManager, { discussionId });
    }

    mandatoryProbesTopic(): Subscribable<any> {
        return this.storage.get(require('./mandatoryProbesTopic'));
    }

    person(personId: StorageArg<string>): Subscribable<any> {
        return this.storage.get(PersonManager, { personId });
    }

    personStatistics(discussionId: StorageArg<string>, personId: StorageArg<string>, status: StorageArg<number>): Subscribable<any> {
        return this.storage.get(require('./statistics/person'), [discussionId, personId, status]);
    }

    personTags(personId: StorageArg<string>) {
        return this.storage.get(PersonTagsManager, [personId]);
    }

    personTopicsCompletion(discussionId: StorageArg<string>, personId: StorageArg<string>): Subscribable<any> {
        return this.storage.get(require('./statistics/person/topicsCompletion'), [discussionId, personId]);
    }

    polls(isAdmin: StorageArg<boolean> = false): Subscribable<any> {
        return this.storage.get(require('./polls'), [isAdmin]);
    }

    poll(pollId: StorageArg<string>): Subscribable<any> {
        return this.storage.get(require('./poll'), [pollId]);
    }

    pollStatistics(pollId: StorageArg<string>): Subscribable<any> {
        return this.storage.get(require('./statistics/poll'), [pollId]);
    }

    simpleTopic(topicId: StorageArg<string>) {
        return this.storage.get(SimpleTopicManager, { topicId });
    }

    simpleTopics(discussionId: StorageArg<string> = rootDiscussionId) {
        return this.storage.get(SimpleTopicsManager, { discussionId });
    }

    task(taskId: StorageArg<string> = rootDiscussionId) {
        return this.storage.get(TaskManager, { taskId });
    }

    threadTags(discussionId: StorageArg<string>, threadId: StorageArg<string>, isEmpty: StorageArg<boolean> = false) {
        return this.storage.get(ThreadTagsManager, [discussionId, threadId, isEmpty]);
    }

    topic(topicId: StorageArg<string>) {
        return this.storage.get(TopicManager, { topicId });
    }

    topicAttachments(topicId: StorageArg<string>, view: AttachmentsView = AttachmentsView.all) {
        return this.storage.get(TopicAttachmentsManager, { topicId, view });
    }

    topicAttachmentsUploads(topicId: StorageArg<string>) {
        return this.storage.get(TopicAttachmentsUploadsManager, { topicId });
    }

    topicComments(topicId: StorageArg<string>, view: StorageArg<number> = CommentsView.all, searchString: StorageArg<string> = '') {
        return this.storage.get(CommentsManager, { topicId, view, searchString });
    }

    topicMandatoryProbes(topicId: StorageArg<string>): Subscribable<any> {
        return this.storage.get(require('./topic/mandatoryProbes'), [topicId]);
    }

    topicMoodTags(topicId: StorageArg<string>): Subscribable<any> {
        return this.storage.get(require('./topic/moodTags'), [topicId]);
    }

    topicQuestions(taskId: StorageArg<string>, topicId: StorageArg<string>): Subscribable<any> {
        return this.storage.get(require('./topic/questions'), [taskId, topicId]);
    }

    topicStatistics(topicId: StorageArg<string>): Subscribable<any> {
        return this.storage.get(require('./statistics/topic'), [topicId]);
    }

    topicSurvey(topicId: StorageArg<string>) {
        return this.storage.get(SurveyManager, { topicId });
    }

    topicSurveyStatistics(topicId: StorageArg<string>) {
        return this.storage.get(SurveyStatisticsManager, { topicId });
    }

    twoFactorAuth(discussionId: StorageArg<string> = rootDiscussionId) {
        return this.storage.get(TwoFactorAuthManager, { discussionId });
    }

    /**
     * @deprecated
     * @param name
     * @param args
     */
    getComputed(name: keyof ManagersContext, ...args: Array<any>) {
        const factory = this[name] as any;

        if (_.isFunction(factory))
            return factory.apply(this, args);
    }

    release(instance: any) {
        this.storage.release(instance);
    }

    dispose() {
        this.effects.dispose();
    }
}

export var instance = new ManagersContext();