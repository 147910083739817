import { URLQueryData } from 'builders/url';
import endpoint from 'endpoints/api';
import events from 'managers/events';
import { apiTopicNotificationData } from 'models/apiTopicNotificationData';
import { JSONWCCAttachment } from 'models/attachments/attachment';
import { JSONDiscussion } from 'models/discussion';
import Repository from 'repositories/generic';
import { BaseService } from 'services/base';
import {JSONCommunityPersonForAddEdit, JSONPersonRegistrationResult} from "../models/communityPerson";

export class DiscussionService extends BaseService {
    private repository = new Repository(endpoint);

    superadminQueries = {
        projects: () => this.repository.fastQuery('superadmin/projects/all'),
        projectsTotals: () => this.repository.fastQuery('superadmin/projects/totals'),
    }

    adminQueries = {
        projects: () => this.repository.fastQuery('projects/all'),
        projectActivities: (projectId: string) => this.repository.fastQuery(`projects/${projectId}/activities`),
        projectsTotals: () => this.repository.fastQuery('projects/totals'),
        projectGroup: (groupId: string) => this.repository.query(`projects/groups/${groupId}`),
        projectGroupPin: (groupId: string) => this.repository.query(`projects/groups/${groupId}/pin`),
        projectGroups: () => this.repository.query('projects/groups'),
        projectGroupProjects: (groupId: string, projectId: string) => this.repository.query(`projects/groups/${groupId}/projects` + (projectId ? '/' + projectId : ''))
    }

    queries = {
        discussion: (discussionId: string) => this.repository.query<never, JSONDiscussion, never, never, never, never, never>(`discussions/${discussionId}`),
        attachments: (discussionId: string) => this.repository.query<never, JSONWCCAttachment, never, never, never, never, never>(`discussions/${discussionId}/attachments`),
        markAllCommentsAsRead: (discussionId: string) => this.repository.query<never, never, never, never, never, never, Array<apiTopicNotificationData>>(`discussions/${discussionId}/unreadComments`),
        markAllCommentsAsReadByPerson: (discussionId: string, personId: string) => this.repository.query(`discussions/${discussionId}/people/${personId}/unreadComments`),
        addParticipantFromScreener: (discussionId: string) => this.repository.postQuery<JSONCommunityPersonForAddEdit, JSONPersonRegistrationResult>(`discussions/${discussionId}/screener/people/registration`),
        updateParticipantFromScreener: (discussionId: string) => this.repository.updateQuery<JSONCommunityPersonForAddEdit, JSONPersonRegistrationResult>(`discussions/${discussionId}/screener/people/registration`),
    }

    subscriptions = {
        discussion: this.signalRSubscription('Discussion'),
        import: this.signalRSubscription('Import'),
        signalRMessages: this.signalRSubscription('SignalRMessages')
    }

    events = {
        newNotificationEvent: this.signalREvent('NewNotificationEvent'),
        deletedNotificationEvent: this.signalREvent('DeletedNotificationEvent'),
        discussionChanged: this.signalREvent('DiscussionIsChanged'),
        signalRMessage: this.signalREvent('SignalRMessage')
    }

    messages = {
        newNotificationEvent: this.signalRMessage('NewNotificationEvent'),
        deletedNotificationEvent: this.signalRMessage('DeletedNotificationEvent')
    }

    /**
    * Returns discussion attachments. Support oData
    * @param discussionId - discussion identity
    * @param oData - oData params
    */
    getAttachments(discussionId: string, oData: URLQueryData) {
        return this.queries.attachments(discussionId).addArgs(oData).toArray();
    }

    /**
     * Marks all comments as readed
     * @param {string} discussionId - discussion identity
     */
    markAllCommentsAsRead(discussionId: string) {
        return (this.queries.markAllCommentsAsRead(discussionId).remove() as JQuery.Promise<Array<apiTopicNotificationData>>).done(topicsData => events.discussionCommentsNotificationsRemoved.trigger(topicsData));
    }

    /**
     * Marks all comments of given person in given discusson as readed
     * @param {string} discussionId - discussion identity
     * @param {string} personId - person identity
     */
    markAllCommentsAsReadByPerson(discussionId: string, personId: string) {
        return this.queries.markAllCommentsAsReadByPerson(discussionId, personId).remove();
    }

    /**
     * Adds a participant to discussion via a public screener
     * @param {string} discussionId - discussion identity
     * @param {string} data - screener data to register
     */
    async addParticipantFromScreener(discussionId: string, data: JSONCommunityPersonForAddEdit) {
        return await this.queries.addParticipantFromScreener(discussionId).firstOrDefaultPost(data);
    }

    /**
     * Updates a participant in discussion via a public screener
     * This is used when we send invites to existing participants and want to collect their responses to a mandatory screener
     * @param {string} discussionId - discussion identity
     * @param {string} data - screener data to register
     */
    async updateParticipantFromScreener(discussionId: string, data: JSONCommunityPersonForAddEdit) {
        return await this.queries.updateParticipantFromScreener(discussionId).update(data);
    }
}