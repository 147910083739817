import { Observable, ObservableArray } from "knockout";
import tagsParser from 'managers/tagsParser';
import WCCEntity from './entity';
import { HeatmapOptions, JSONHeatmapOptions } from "./heatmap/options";
import ScreenRecordingOptions, { JSONScreenRecordingOptions } from './screenRecordingOptions';
import { JSONTopicCard, TopicCard } from "./topicCard";
import { JSONTopicCardsGroup, TopicCardsGroup } from "./topicCardsGroup";
import { JSONTopicCardsScale, TopicCardsScale } from "./topicCardsScale";
import TopicQuestion, { JSONTopicQuestion } from './topicQuestion';
import VideoPresentationOptions, { JSONVideoPresentationOptions } from './videoPresentationOptions';
import WCCAttachment, {JSONWCCAttachment} from "./attachments/attachment";
import attachmentsFactory from "../factories/attachments";

const { WccCDNUrl } = settings;

function processTags(content = ''): string {
    return tagsParser.processTags(content).trim();
}

export interface JSONTopic {
    Id?: string
    DiscussionTopicId?: string
    DiscussionId?: string
    GroupId?: string
    CreatorId?: string
    CreatorImageURL?: string

    Title?: string
    QuickSell?: string
    ThoroughDescription?: string
    ModeratorNotes?: string
    BlogSummary?: string
    About?: string

    TitlePlaceholder?: string
    CommentPlaceholder?: string
    ReplyPlaceholder?: string
    NextTopicButtonLabelInContent?: string
    TopicQuestionContinueButtonText?: string

    SurveyCommentTemplate?: string
    SavedModeratorPosts?: string

    Media?: string
    MediaData?: string
    MediaImageToken?: string
    MediaImageOriginalFileName?: string
    MediaImageFileExtension?: string
    MediaImageHash?: string
    MediaImageCreatorId?: string

    Type?: number
    TopicType?: number
    TopicContentType?: number
    ActivityType?: number
    MediaType?: number
    ParticipantReplyType?: number
    MediaCaptionType?: number
    ParticipantCommentType?: number    
    PostCreatorRestrictionType?: number
    TopicCompletionNotificationsType?: number
    TopicQuestionContinueOptionType?: number
    VirtualPinBoardOptions?: number
    AllowedMediaTypes?: string
    AllowedAttachmentExtensions: string
    AllowedAttachmentSize?: number
    PostStatus?: number
    CommentsVisibility?: number
    CommentsByPeopleFiltering?: number
    CommentsByTagsFiltering?: number
    CommentsAdditionalVisibility?: number
    TopicVisibility?: number
    TopicCompletion?: number
    TopicOrder?: number
    CommentsLimit?: number

    DeleteCommentTimeout?: number
    EditCommentTimeout?: number

    PrivateTopicAutoJumpType: number
    PrivateTopicAutoJumpPostsNumber: number
    PrivateTopicAutoJumpMessage?: string

    IsNew?: boolean
    IsVisible?: boolean
    IsGroup?: boolean
    IsAnswered?: boolean
    GroupIsVisible?: boolean
    UsersCanComment?: boolean
    IsThreadMediaMandatory?: boolean
    SurveyAllowsMultipleResponses?: boolean
    ShowNextTopicButtonInContent?: boolean
    MoodTagIsMandatory?: boolean
    PinCommentIsMandatory?: boolean
    HasComments?: boolean
    HasAttachments?: boolean
    HasThumbnail?: boolean
    HasTags?: boolean

    CommentsFilterTagIDs?: string[]
    CommentsFilterPeopleTagIDs?: string[]
    CommentsAdditionalOptionsTagIDs?: string[]
    TopicVisibilityTagsIDs?: string[]
    PreviousTopicIDs?: string[]
    MoodTagsIDs?: string[]

    CreateDate?: string
    EditDate?: string
    VisibleAfter?: string
    VisibleBefore?: string
    GroupVisibleAfter?: string
    GroupVisibleBefore?: string

    IdeationRankingDescription?: string
    IdeationUpvotes?: number
    IdeationUpvotesMaxPerIdea?: number
    IdeationDownvotes?: number
    IdeationDownvotesMaxPerIdea?: number
    IdeationStage?: number
    IdeationStagingStage?: number

    TopicCards?: JSONTopicCard[]
    TopicCardGroups?: JSONTopicCardsGroup[]
    TopicCardScales?: JSONTopicCardsScale[]
    TopicCardsCustomGroupDetailType?: number
    TopicCardsCustomGroupsCountType?: number
    TopicCardsCustomGroupsMinCount?: number
    TopicCardsCustomGroupsMaxCount?: number
    TopicCardsGroupType?: number
    TopicCardsSortLimitTypes?: number
    TopicCardsSortingType?: number
    CardSortMaxCards?: number
    CardSortMinCards?: number
    TopicCardsSortRenderingType?: number
    CardSortDisplayType?: number
    CardSortHelpDesktop?: string
    CardSortHelpMobile?: string
    CardScoreHeader?: string
    CardScoreInstructions?: string
    CardScoreScaleMarkingType?: number

    HeatMapOptions?: JSONHeatmapOptions
    VideoPresentationOptions?: JSONVideoPresentationOptions
    ScreenRecordingOptions?: JSONScreenRecordingOptions

    TopicQuestions?: Array<JSONTopicQuestion>
    
    Attachments?: Array<JSONWCCAttachment>
}

export class Topic extends WCCEntity<JSONTopic> {
    id: Observable<string | undefined>
    topicId: Observable<string | undefined>
    discussionTopicId: Observable<string | undefined>
    discussionId: Observable<string | undefined>
    groupId: Observable<string | undefined>
    creatorId: Observable<string | undefined>
    creatorImageURL: Observable<string>

    title: Observable<string>
    quickSell: Observable<string>
    thoroughDescription: Observable<string>
    moderatorNotes: Observable<string>
    blogSummary: Observable<string>
    about: Observable<string>
    savedModeratorPosts: Observable<string>
    surveyCommentTemplate: Observable<string>

    titlePlaceholder: Observable<string | undefined>
    commentPlaceholder: Observable<string | undefined>
    replyPlaceholder: Observable<string | undefined>
    nextTopicButtonLabelInContent: Observable<string>
    topicQuestionContinueButtonText: Observable<string>

    media: Observable<string | undefined>
    mediaType: Observable<number | undefined>
    mediaData: Observable<string | undefined>
    mediaImageToken: Observable<string>
    mediaImageHash: Observable<string>
    mediaImageOriginalFileName: Observable<string>
    mediaImageFileExtension: Observable<string>
    mediaImageCreatorId: Observable<string | undefined>

    type: Observable<number | undefined>
    topicType: Observable<number | undefined>
    topicContentType: Observable<number | undefined>
    activityType: Observable<number | undefined>
    postStatus: Observable<number | undefined>
    commentsVisibility: Observable<number | undefined>
    commentsByPeopleFiltering: Observable<number | undefined>
    commentsByTagsFiltering: Observable<number | undefined>
    commentsAdditionalVisibility: Observable<number | undefined>
    topicVisibility: Observable<number | undefined>
    topicCompletion: Observable<number | undefined>
    participantReplyType: Observable<number | undefined>
    postCreatorRestrictionType: Observable<number | undefined>
    mediaCaptionType: Observable<number | undefined>
    participantsCommentType: Observable<number | undefined>
    participantsReplyType: Observable<number | undefined>
    topicCompletionNotificationsType: Observable<number | undefined>
    topicQuestionContinueOptionType: Observable<number>

    allowedMediaTypes: Observable<string | undefined>
    allowedAttachmentExtensions: Observable<Array<string>>
    allowedAttachmentSize: Observable<number | undefined>

    topicOrder: Observable<number | undefined>
    commentsLimit: Observable<number>

    deleteCommentTimeout: Observable<number>
    editCommentTimeout: Observable<number>

    privateTopicAutoJumpType: Observable<number>
    privateTopicAutoJumpPostsNumber: Observable<number>
    privateTopicAutoJumpMessage: Observable<string>

    isNew: Observable<boolean>
    isVisible: Observable<boolean>
    usersCanComment: Observable<boolean>
    isThreadMediaMandatory: Observable<boolean>
    isGroup: Observable<boolean>
    isAnswered: Observable<boolean>
    hasTags: Observable<boolean>
    surveyAllowsMultipleResponses: Observable<boolean>
    showNextTopicButtonInContent: Observable<boolean>
    moodTagIsMandatory: Observable<boolean>
    pinCommentIsMandatory: Observable<boolean>
    hasComments: Observable<boolean>
    hasAttachments: Observable<boolean>
    attachments: ObservableArray<WCCAttachment>
    hasThumbnail: Observable<boolean>
    groupIsVisible: Observable<boolean>

    commentsFilterTagIDs: Observable<Array<string>>
    commentsFilterPeopleTagIDs: Observable<Array<string>>
    commentsAdditionalOptionsTagIDs: Observable<Array<string>>
    previousTopicIDs: Observable<Array<string>>
    topicVisibilityTagsIDs: Observable<Array<string>>
    moodTagsIDs: Observable<Array<string>>

    pinboardOptions: Observable<number | undefined>
    heatMapOptions: Observable<HeatmapOptions | undefined>
    videoPresentationOptions: Observable<VideoPresentationOptions | undefined>
    screenRecordingOptions: Observable<ScreenRecordingOptions | undefined>

    ideationRankingDescription: Observable<string | undefined>
    ideationUpvotes: Observable<number>
    ideationUpvotesMaxPerIdea: Observable<number>
    ideationDownvotes: Observable<number>
    ideationDownvotesMaxPerIdea: Observable<number>
    ideationStage: Observable<number>

    cards: ObservableArray<TopicCard>
    cardsGroups: ObservableArray<TopicCardsGroup>
    cardsScales: ObservableArray<TopicCardsScale>
    customCardsGroupsDetailsType: Observable<number | undefined>
    cardsCustomGroupsCountType: Observable<number | undefined>
    cardsCustomGroupsMinCount: Observable<number>
    cardsCustomGroupsMaxCount: Observable<number>
    cardsGroupsType: Observable<number | undefined>
    cardsLimitType: Observable<number | undefined>
    cardsSortType: Observable<number | undefined>
    cardsSortMaxLimit: Observable<number>
    cardsSortMinLimit: Observable<number>
    cardsSortRenderingType: Observable<number | undefined>
    cardSortDisplayType: Observable<number | undefined>
    cardSortHelpDesktop: Observable<string>
    cardSortHelpMobile: Observable<string>
    cardScoreHeader: Observable<string>
    cardScoreInstructions: Observable<string>
    cardScoreScaleMarkingType: Observable<number>

    topicQuestions: ObservableArray<TopicQuestion>

    createDate: Observable<Date | undefined>
    editDate: Observable<string | undefined>
    visibleAfter: Observable<string | undefined>
    visibleBefore: Observable<string | undefined>
    groupVisibleAfter: Observable<string | undefined>
    groupVisibleBefore: Observable<string | undefined>

    parsedAllowedMediaTypes = ko.pureComputed(() => this.parseMediaTypesString(this.allowedMediaTypes() ?? ''))

    imageURL = ko.pureComputed(() => this.media() ? (`${settings.wccApiUrl}/api/resources/taskimages/${this.media()}`).toLowerCase() : undefined);
    fullImageURL = ko.pureComputed(() => this.media() ? (`${settings.wccApiUrl}/api/resources/taskimages/${this.media()}`).toLowerCase() : undefined);

    canHideQuickSell = ko.pureComputed(() => !this.quickSell() || this.quickSell() == this.title());

    isTextCommentsTopic = ko.pureComputed(() => this.topicContentType() === enums.TopicContentTypes.TextComments.value);
    isHeatMap = ko.pureComputed(() => this.topicContentType() === enums.TopicContentTypes.HeatMap.value);
    isMediaOnly = ko.pureComputed(() => this.topicContentType() === enums.TopicContentTypes.MediaOnly.value);
    isDocumentsCollection = ko.pureComputed(() => this.topicContentType() === enums.TopicContentTypes.CollectionOfDocuments.value);
    isMultipleComments = ko.pureComputed(() => this.topicContentType() === enums.TopicContentTypes.MultipleComments.value);
    isSurvey = ko.pureComputed(() => this.topicContentType() === enums.TopicContentTypes.Survey.value);
    isExternalSurvey = ko.pureComputed(() => this.topicContentType() === enums.TopicContentTypes.ExternalSurvey.value);
    isIdeation = ko.pureComputed(() => this.topicContentType() === enums.TopicContentTypes.Ideation.value);
    isPinBoard = ko.pureComputed(() => this.topicContentType() === enums.TopicContentTypes.VirtualPinBoard.value);
    isInformationOnlyTopic = ko.pureComputed(() => this.topicContentType() === enums.TopicContentTypes.InformationOnly.value);
    isVideoPresentation = ko.pureComputed(() => this.topicContentType() === enums.TopicContentTypes.VideoPresentation.value);
    isVoxPop = ko.pureComputed(() => this.topicContentType() === enums.TopicContentTypes.VoxPop.value);
    isCardSort = ko.pureComputed(() => this.topicContentType() === enums.TopicContentTypes.CardSort.value);
    isCardScore = ko.pureComputed(() => this.topicContentType() === enums.TopicContentTypes.CardScore.value);
    isScreenRecording = ko.pureComputed(() => this.topicContentType() == enums.TopicContentTypes.ScreenRecording.value);
    isAudioRecording = ko.pureComputed(() => this.topicContentType() == enums.TopicContentTypes.AudioUpload.value);

    hasSpecialCommentForm = ko.pureComputed(() => this.isSurvey() || this.isPinBoard());
    isMediaCaptionRequired = ko.pureComputed(() => this.mediaCaptionType() === enums.ThreadMediaCaptionTypes.Required.value);
    isOptional = ko.pureComputed(() => this.topicCompletionNotificationsType() === enums.TopicCompletionNotificationsTypes.None.value);
    shouldBeAnswered = ko.pureComputed(() => !this.isOptional() && !this.isAnswered());
    commentsAreHidden = ko.pureComputed(() => this.commentsVisibility() === enums.CommentsVisibilityType.HideAll.value);

    constructor(jsonTopic?: JSONTopic) {
        super();

        const commentsLimit = this.createField(jsonTopic, 'CommentsLimit', 0);

        this.id = this.createField(jsonTopic, 'Id');
        this.topicId = this.id;
        this.type = this.createField(jsonTopic, 'Type');
        this.isNew = this.createField(jsonTopic, 'IsNew', false);
        this.topicType = this.createField(jsonTopic, 'TopicType');
        this.discussionTopicId = this.createField(jsonTopic, 'DiscussionTopicId');
        this.discussionId = this.createField(jsonTopic, 'DiscussionId');
        this.creatorId = this.createField(jsonTopic, 'CreatorId');
        this.visibleAfter = this.createField(jsonTopic, 'VisibleAfter');
        this.visibleBefore = this.createField(jsonTopic, 'VisibleBefore');
        this.title = this.createField(jsonTopic, 'Title', '').mapSingle(processTags);
        this.quickSell = this.createField(jsonTopic, 'QuickSell', '').mapSingle(processTags);
        this.topicContentType = this.createField(jsonTopic, 'TopicContentType');
        this.activityType = this.createField(jsonTopic, 'ActivityType');
        this.postStatus = this.createField(jsonTopic, 'PostStatus');
        this.isVisible = this.createField(jsonTopic, 'IsVisible', true);
        this.commentsVisibility = this.createField(jsonTopic, 'CommentsVisibility');
        this.commentsByPeopleFiltering = this.createField(jsonTopic, 'CommentsByPeopleFiltering');
        this.commentsByTagsFiltering = this.createField(jsonTopic, 'CommentsByTagsFiltering');
        this.commentsAdditionalVisibility = this.createField(jsonTopic, 'CommentsAdditionalVisibility');
        this.createDate = this.createMappedField(jsonTopic, 'CreateDate', WCCEntity.dateMapper);
        this.editDate = this.createField(jsonTopic, 'EditDate');
        this.thoroughDescription = this.createField(jsonTopic, 'ThoroughDescription', '').mapSingle(processTags);
        this.moderatorNotes = this.createField(jsonTopic, 'ModeratorNotes', '').mapSingle(processTags);
        this.blogSummary = this.createField(jsonTopic, 'BlogSummary', '');
        this.about = this.createField(jsonTopic, 'About', '');
        this.mediaType = this.createField(jsonTopic, 'MediaType');
        this.media = this.createField(jsonTopic, 'Media');
        this.mediaImageToken = this.createField(jsonTopic, 'MediaImageToken', '');
        this.mediaImageOriginalFileName = this.createField(jsonTopic, 'MediaImageOriginalFileName', '');
        this.mediaImageFileExtension = this.createField(jsonTopic, 'MediaImageFileExtension', '');
        this.allowedMediaTypes = this.createField(jsonTopic, 'AllowedMediaTypes');
        this.allowedAttachmentExtensions = this.createMappedField(jsonTopic, 'AllowedAttachmentExtensions', { map: str => str.split(',').map(str => str.trim()), mapReverse: list => list.join(',') }, new Array<string>());
        this.allowedAttachmentSize = this.createField(jsonTopic, 'AllowedAttachmentSize');
        this.topicOrder = this.createField(jsonTopic, 'TopicOrder');
        this.usersCanComment = this.createField(jsonTopic, 'UsersCanComment', false);
        this.commentsFilterTagIDs = this.createCollection(jsonTopic, 'CommentsFilterTagIDs');
        this.commentsFilterPeopleTagIDs = this.createCollection(jsonTopic, 'CommentsFilterPeopleTagIDs');
        this.commentsAdditionalOptionsTagIDs = this.createCollection(jsonTopic, 'CommentsAdditionalOptionsTagIDs')
        this.previousTopicIDs = this.createCollection(jsonTopic, 'PreviousTopicIDs');
        this.topicVisibilityTagsIDs = this.createCollection(jsonTopic, 'TopicVisibilityTagsIDs');
        this.moodTagsIDs = this.createCollection(jsonTopic, 'MoodTagsIDs');
        this.topicVisibility = this.createField(jsonTopic, 'TopicVisibility');
        this.topicCompletion = this.createField(jsonTopic, 'TopicCompletion');
        this.isThreadMediaMandatory = this.createField(jsonTopic, 'IsThreadMediaMandatory', false);
        this.titlePlaceholder = this.createField(jsonTopic, 'TitlePlaceholder').mapNotNull(processTags);
        this.commentPlaceholder = this.createField(jsonTopic, 'CommentPlaceholder').mapNotNull(processTags);
        this.replyPlaceholder = this.createField(jsonTopic, 'ReplyPlaceholder').mapNotNull(processTags);
        this.savedModeratorPosts = this.createField(jsonTopic, 'SavedModeratorPosts', '');

        this.privateTopicAutoJumpType = this.createField(jsonTopic, 'PrivateTopicAutoJumpType', enums.PrivateTopicAutoJumpTypes.DoNothing.value);
        this.privateTopicAutoJumpPostsNumber = this.createField(jsonTopic, 'PrivateTopicAutoJumpPostsNumber', 0);
        this.privateTopicAutoJumpMessage = this.createField(jsonTopic, 'PrivateTopicAutoJumpMessage', messages.ActivityHasBeenAnswered);
        
        this.surveyAllowsMultipleResponses = this.createField(jsonTopic, 'SurveyAllowsMultipleResponses', false);
        this.participantReplyType = this.createField(jsonTopic, 'ParticipantReplyType');
        this.postCreatorRestrictionType = this.createField(jsonTopic, 'PostCreatorRestrictionType');
        this.mediaCaptionType = this.createField(jsonTopic, 'MediaCaptionType');
        this.participantsCommentType = this.createField(jsonTopic, 'ParticipantCommentType');
        this.participantsReplyType = this.createField(jsonTopic, 'ParticipantReplyType');
        this.showNextTopicButtonInContent = this.createField(jsonTopic, 'ShowNextTopicButtonInContent', false);
        this.nextTopicButtonLabelInContent = this.createField(jsonTopic, 'NextTopicButtonLabelInContent', '');
        this.topicQuestionContinueButtonText = this.createField(jsonTopic, 'TopicQuestionContinueButtonText', labels.ClickToContinue);
        this.topicCompletionNotificationsType = this.createField(jsonTopic, 'TopicCompletionNotificationsType');
        this.topicQuestionContinueOptionType = this.createField(jsonTopic, 'TopicQuestionContinueOptionType', enums.TopicQuestionContinueOptionTypes.NoPause.value)
        this.pinboardOptions = this.createField(jsonTopic, 'VirtualPinBoardOptions');

        this.moodTagIsMandatory = this.createField(jsonTopic, 'MoodTagIsMandatory', false);
        this.pinCommentIsMandatory = this.createField(jsonTopic, 'PinCommentIsMandatory', false);
        this.surveyCommentTemplate = this.createField(jsonTopic, 'SurveyCommentTemplate', '');
        this.mediaData = this.createField(jsonTopic, 'MediaData');

        this.ideationRankingDescription = this.createField(jsonTopic, 'IdeationRankingDescription');
        this.ideationUpvotes = this.createField(jsonTopic, 'IdeationUpvotes', 0);
        this.ideationUpvotesMaxPerIdea = this.createField(jsonTopic, 'IdeationUpvotesMaxPerIdea', 0);
        this.ideationDownvotes = this.createField(jsonTopic, 'IdeationDownvotes', 0);
        this.ideationDownvotesMaxPerIdea = this.createField(jsonTopic, 'IdeationDownvotesMaxPerIdea', 0);

        const ideationStage = this.createField(jsonTopic, 'IdeationStage', 0);
        const ideationStagingStage = this.createField(jsonTopic, 'IdeationStagingStage', 0);

        this.ideationStage = settings.isStaging ? ideationStagingStage : ideationStage;

        this.cards = this.createCollection(jsonTopic, 'TopicCards', jsonCard => new TopicCard(jsonCard));
        this.cardsGroups = this.createCollection(jsonTopic, 'TopicCardGroups', jsonCardsGroup => new TopicCardsGroup(jsonCardsGroup));
        this.cardsScales = this.createCollection(jsonTopic, 'TopicCardScales', jsonCardsScale => new TopicCardsScale(jsonCardsScale));
        this.customCardsGroupsDetailsType = this.createField(jsonTopic, 'TopicCardsCustomGroupDetailType');
        this.cardsCustomGroupsCountType = this.createField(jsonTopic, 'TopicCardsCustomGroupsCountType');
        this.cardsCustomGroupsMinCount = this.createField(jsonTopic, 'TopicCardsCustomGroupsMinCount', 0);
        this.cardsCustomGroupsMaxCount = this.createField(jsonTopic, 'TopicCardsCustomGroupsMaxCount', 0);
        this.cardsGroupsType = this.createField(jsonTopic, 'TopicCardsGroupType');
        this.cardsLimitType = this.createField(jsonTopic, 'TopicCardsSortLimitTypes');
        this.cardsSortType = this.createField(jsonTopic, 'TopicCardsSortingType');
        this.cardsSortMaxLimit = this.createField(jsonTopic, 'CardSortMaxCards', 0);
        this.cardsSortMinLimit = this.createField(jsonTopic, 'CardSortMinCards', 0);
        this.cardsSortRenderingType = this.createField(jsonTopic, 'TopicCardsSortRenderingType');
        this.cardSortDisplayType = this.createField(jsonTopic, 'CardSortDisplayType');
        this.cardSortHelpDesktop = this.createField(jsonTopic, 'CardSortHelpDesktop', '');
        this.cardSortHelpMobile = this.createField(jsonTopic, 'CardSortHelpMobile', '');
        this.cardScoreHeader = this.createField(jsonTopic, 'CardScoreHeader', '');
        this.cardScoreInstructions = this.createField(jsonTopic, 'CardScoreInstructions', '');
        this.cardScoreScaleMarkingType = this.createField(jsonTopic, 'CardScoreScaleMarkingType', enums.CardScoreScaleMarkingTypes.Unknown.value);

        this.heatMapOptions = this.createMappedField(jsonTopic, 'HeatMapOptions', { map: jsonOptions => new HeatmapOptions(jsonOptions), mapReverse: options => options.toJSON() });
        this.videoPresentationOptions = this.createMappedField(jsonTopic, 'VideoPresentationOptions', jsonOptions => new VideoPresentationOptions(jsonOptions));
        this.screenRecordingOptions = this.createMappedField(jsonTopic, 'ScreenRecordingOptions', jsonOptions => new ScreenRecordingOptions(jsonOptions));

        this.topicQuestions = this.createCollection(jsonTopic, 'TopicQuestions', jsonQuestion => new TopicQuestion(jsonQuestion));

        this.hasComments = this.createField(jsonTopic, 'HasComments', false as boolean);
        this.hasAttachments = this.createField(jsonTopic, 'HasAttachments', false as boolean);
        this.hasThumbnail = this.createField(jsonTopic, 'HasThumbnail', false as boolean);
        this.mediaImageHash = this.createField(jsonTopic, 'MediaImageHash', '');
        this.isGroup = this.createField(jsonTopic, 'IsGroup', false as boolean);
        this.groupId = this.createField(jsonTopic, 'GroupId');
        this.groupIsVisible = this.createField(jsonTopic, 'GroupIsVisible', false as boolean);
        this.groupVisibleAfter = this.createField(jsonTopic, 'GroupVisibleAfter');
        this.groupVisibleBefore = this.createField(jsonTopic, 'GroupVisibleBefore');
        
        this.attachments = this.createCollection(jsonTopic, 'Attachments', jsonAttachment => attachmentsFactory.createAttachment(jsonAttachment));

        this.isAnswered = this.createField(jsonTopic, 'IsAnswered', false as boolean);
        this.hasTags = this.createField(jsonTopic, 'HasTags', false as boolean);

        this.mediaImageCreatorId = this.createField(jsonTopic, 'MediaImageCreatorId');
        this.creatorImageURL = this.createField(jsonTopic, 'CreatorImageURL', '');

        this.editCommentTimeout = this.createField(jsonTopic, 'EditCommentTimeout', -1);
        this.deleteCommentTimeout = this.createField(jsonTopic, 'DeleteCommentTimeout', -1);

        this.commentsLimit = ko.pureComputed({
            read: () => {
                if (this.isSurvey()) {
                    return this.surveyAllowsMultipleResponses() ? 0 : 1;
                } if (this.isHeatMap()) {
                    const options = this.heatMapOptions();
                    const maxPinsCount = options?.maxPins() ?? 0;
                    const unlimitedPins = options?.unlimitedPins() ?? false as boolean;

                    return unlimitedPins ? 0 : maxPinsCount;
                } else if (this.isCardScore() || this.isVideoPresentation() || this.isPinBoard() || this.isMultipleComments()) {
                    return 1;
                } else {
                    return commentsLimit();
                }
            },

            write: commentsLimit
        });
    }

    private parseMediaTypesString(str: string) {
        return str.split(',')
            .map(str => parseInt(str.trim()))
            .filter(n => _.isNumber(n) && !_.isNaN(n));
    }
}