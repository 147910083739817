import { inject, injectable } from 'inversify';
import { wccModules } from '../../enums/wccModules';
import { ISignalRCoreManager } from './core/interfaces/main';

class SignalRMessage<T extends Array<any>> {
    constructor(
        private signalRManager: ISignalRCoreManager,
        private name: string) { }

    async send(...args: T) {
        await this.signalRManager.sendMessage(this.name, ...args);            
    }
}

@injectable()
export default class SignalRMessagesManager {
    constructor(
        @inject(wccModules.signalRManager) private signalRManager: ISignalRCoreManager
    ) { }

    participantWatchingVideo = this.create<[topicId: string, src: string, progress: number]>('ParticipantWatchingVideo');
    topicMemberIsWorking = this.create<[topicId: string]>('TopicMemberIsWorking');

    private create<T extends Array<any>>(name: string) {
        return new SignalRMessage<T>(this.signalRManager, name);
    }
}