export var wccModules = {
    container: Symbol('Container'),
    scopeFactory: Symbol('ScopeFactory'),

    componentConfig: Symbol('ComponentModel'),
    componentData: Symbol('ComponentData'),
    componentInfo: Symbol('ComponentInfo'),

    servicesContext: Symbol('ServicesContext'),
    managersContext: Symbol('ManagersContext'),
    managerConfig: Symbol('ManagerConfig'),

    storage: Symbol('Storage'),
    effects: Symbol('Effects'),

    fineUploaderFactory: Symbol('FineUploaderFactory'),
    videoPlayerComponentFactory: Symbol('VideoPlayerComponentFactory'),
    wordsMapFactory: Symbol('WordsMapFactory'),
    
    signalREvents: Symbol('SignalREvents'),
    signalRMessages: Symbol('SignalRMessages'),
    signalRCurrentUserSession: Symbol('SignalRCurrentUserSession'),

    signalRManager: Symbol('SignalRManager'),
    signalRTokenManager: Symbol('SignalRTokenManager'),
    signalRConnectionManager: Symbol('SignalRConnectionManager'),
    signalRMessagesManager: Symbol('SignalRMessagesManager'),
    signalRSubscriptionsManager: Symbol('SignalRSubscriptionsManager'),
    signalREventsManager: Symbol('SignalREventsManager'),
    signalRModulesManager: Symbol('SignalRModulesManager'),

    captchaManager: Symbol('CaptchaManager'),

    attachmentDuplicator: Symbol('AttachmentDuplicator'),
    attachmentLayoutFactory: Symbol('AttachmentLayoutFactory'),
    userFilesDuplicator: Symbol('UserFilesDuplicator'),

    topicMessagesAccess: Symbol('TopicMessagesAccessManager'),
    topicMessagesSaver: Symbol('TopicMessagesSaver'),

    communityTemplateTopicAvailableWidgetVariablesLoader: Symbol('communityTemplateTopicAvailableWidgetVariablesLoader'),
    communityTemplateTopicsGroupTopicLoader: Symbol('CommunityTemplateTopicsGroupTopicLoader'),
    communityTemplateTopicWidgetVariableValueLoader: Symbol('CommunityTemplateTopicWidgetVariableValueLoader'),
        
    accountService: Symbol('AccountService'),
    activitiesService: Symbol('ActivitiesService'),
    attributesDataService: Symbol('AttributesDataService'),
    attachmentsService: Symbol('AttachmentsService'),
    clientsService: Symbol('ClientsService'),
    commentsService: Symbol('CommentsService'),
    communitiesService: Symbol('CommunitiesService'),
    contactProfilerService: Symbol('ContactProfilerService'),
    discussionService: Symbol('DiscussionService'),    
    emailsService: Symbol('EmailsService'),
    loginService: Symbol('LoginService'),    
    initialEndpointDataService: Symbol('InitialEndpointDataService'),
    openTokService: Symbol('OpenTokService'),
    peopleService: Symbol('PeopleService'),
    pixabayService: Symbol('PixabayService'),
    rewardsService: Symbol('RewardsService'),
    servicePipelinesService: Symbol('ServicePipelinesService'),
    statisticsService: Symbol('StatisticsService'),
    signalRService: Symbol('SignalRService'),
    surveysService: Symbol('SurveysService'),
    systemService: Symbol('SystemService'),
    tagsService: Symbol('TagsService'),
    tasksService: Symbol('TasksService'),
    timezoneService: Symbol('TimezoneServicer'),
    topicsService: Symbol('TopicsService'), 
    topicCompletionActivitiesService: Symbol('TopicCompletionActivitiesService'),
    twoFactorAuthService: Symbol('TwoFactorAuthService'),
    unsplashService: Symbol('UnsplashService'),    
    userFilesService: Symbol('UserFilesService'),    
    websitesService: Symbol('WebSitesService')
}