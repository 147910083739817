import endpoint from 'endpoints/api';
import { injectable } from 'inversify';
import Repository from 'repositories/generic';
import { JSONJWT } from '../models/jwt';
import { ISignalRService } from './iSignalR';

@injectable()
export class SignalRService implements ISignalRService {
    private repository = new Repository(endpoint);

    tokenQuery = () => this.repository.entityGetQuery<JSONJWT>(`signalr/token`);
}