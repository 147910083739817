import { Observable, ObservableArray, Subscribable } from "knockout";
import WCCEntity from "./entity";
import { JSONTag, Tag } from "./tag";
import { TopicThread } from './topicThread';

export interface JSONPerson {
    PersonId?: string
    TaskMemberCode?: string
    Title?: string
    FirstName?: string
    LastName?: string
    FullName?: string
    Nickname?: string
    EmailAddress?: string
    ImageURL?: string
    TimezoneCode?: string
    TaskStatus?: number
    AnonymityApplied?: boolean
    AgreedToTermsAndConditions?: boolean
    IsTwoFactorAuthVerified?: boolean

    Tags?: Array<JSONTag>
}

export class Person extends WCCEntity<JSONPerson> {
    personId: Observable<string | undefined>
    taskMemberCode: Observable<string | undefined>
    firstName: Observable<string | undefined>
    lastName: Observable<string | undefined>
    fullName: Observable<string | undefined>
    nickname: Observable<string | undefined>
    emailAddress: Observable<string | undefined>
    imageURL: Observable<string | undefined>
    timezoneCode: Observable<string | undefined>
    status: Observable<number | undefined>
    anonymityApplied: Observable<boolean>
    agreedToTermsAndConditions: Observable<boolean>
    isTwoFactorAuthVerified: Observable<boolean>;

    tags: ObservableArray<Tag>

    nicknameOrName: Subscribable<string | undefined>

    isModerator: Subscribable<boolean>
    isObserver: Subscribable<boolean>
    isMember: Subscribable<boolean>
    isInvited: Subscribable<boolean>
    isDeclined: Subscribable<boolean>
    isCompleted: Subscribable<boolean>
    

    isRegular: Subscribable<boolean>
    isModeratorOrObserver: Subscribable<boolean>
    noAccess: Subscribable<boolean>

    static fromThread(thread: TopicThread) {
        return new Person({
            PersonId: thread.postedById(),
            ImageURL: thread.creatorImageUrl(),
            FullName: thread.creatorName(),
            Nickname: thread.creatorNickname(),
            TaskStatus: thread.creatorStatus()
        });
    }

    static create(data: JSONPerson) {
        return new Person(data);
    }

    constructor(data?: JSONPerson) {
        super();

        this.personId = this.createField(data, 'PersonId');
        this.taskMemberCode = this.createField(data, 'TaskMemberCode');
        this.firstName = this.createField(data, 'FirstName');
        this.lastName = this.createField(data, 'LastName');
        this.fullName = this.createField(data, 'FullName');
        this.nickname = this.createField(data, 'Nickname');
        this.emailAddress = this.createField(data, 'EmailAddress');
        this.imageURL = this.createField(data, 'ImageURL');
        this.timezoneCode = this.createField(data, 'TimezoneCode');
        this.status = this.createField(data, 'TaskStatus');
        this.anonymityApplied = this.createField(data, 'AnonymityApplied', false);
        this.agreedToTermsAndConditions = this.createField(data, 'AgreedToTermsAndConditions', false);
        this.isTwoFactorAuthVerified = this.createField(data, 'IsTwoFactorAuthVerified', false);

        this.tags = this.createCollection(data, 'Tags', jsonTag => new Tag(jsonTag));

        this.nicknameOrName = ko.pureComputed(() => (this.nickname() ?? '').length > 0 ? this.nickname() : this.fullName());

        this.isModerator = this.status.is(enums.MembershipStatusTypes.Moderator.value);
        this.isObserver = this.status.is(enums.MembershipStatusTypes.Observer.value);
        this.isMember = this.status.is(enums.MembershipStatusTypes.Member.value);
        this.isInvited = this.status.is(enums.MembershipStatusTypes.Invited.value);
        this.isDeclined = this.status.is(enums.MembershipStatusTypes.Declined.value);
        this.isCompleted = this.status.is(enums.MembershipStatusTypes.Completed.value);

        this.isRegular = ko.pureComputed(() => !this.isModerator() && !this.isObserver());
        this.isModeratorOrObserver = ko.pureComputed(() => this.isModerator() || this.isObserver());
        this.noAccess = ko.pureComputed(() => !this.isModerator() && !this.isObserver() && !this.isMember());
    }

    updateWith(jsonModel: JSONPerson) {
        this.update(jsonModel);
    }    
}