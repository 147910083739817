import { SimpleTopic, JSONSimpleTopic } from 'models/simpleTopic';
import { ObservableArray } from 'knockout';

export interface JSONSimpleTopicsGroup extends JSONSimpleTopic {
    Topics?: Array<JSONSimpleTopic>
}

export class SimpleTopicsGroup extends SimpleTopic<JSONSimpleTopicsGroup> {
    topics: ObservableArray<SimpleTopic>
    isEmpty = ko.pureComputed(() => this.topics().length === 0)

    constructor(data: JSONSimpleTopicsGroup) {
        super(data);

        this.topics = this.createCollection(data, 'Topics', { key: 'id', map: jsonTopic => new SimpleTopic(jsonTopic), mapReverse: topic => topic.toJson(), update: (topic, newTopic) => topic.update(newTopic.toJson()) });

        this.toDoCount = ko.pureComputed({
            read: () => this.topics().reduce((result, topic) => result + topic.toDoCount(), 0),
            write: this._toDoCount
        });

        this.totalPostsCount = ko.pureComputed({
            read: () => this.topics().reduce((result, topic) => result + topic.totalPostsCount(), 0),
            write: this._totalPostsCount
        });

        this.recentPostsCount = ko.pureComputed({
            read: () => this.topics().reduce((result, topic) => result + topic.recentPostsCount(), 0),
            write: this._recentPostsCount
        });

        this.toDoCountForObsPosts = ko.pureComputed({
            read: () => this.topics().reduce((result, topic) => result + topic.toDoCountForObsPosts(), 0),
            write: this._toDoCountForObsPosts
        });

        this.isAnswered = ko.pureComputed({ read: () => this.topics().every(topic => topic.isAnswered()), write: this._isAnswered });
    }

    addTopic(topicToAdd: SimpleTopic) {
        var alreadyAdded = topicToAdd.id() != null && _(this.topics()).any(t => t.id() === topicToAdd.id());

        if (!alreadyAdded) {
            const insertIdx = this.topics().reduce((result, topic, idx) => {
                const topicToAddOrder = topicToAdd.topicOrder() ?? 0;
                const topicOrder = topic.topicOrder() ?? 0;

                return topicToAddOrder < topicOrder ? result : idx + 1;
            }, 0);

            this.topics.splice(insertIdx, 0, topicToAdd);
        }
    }

    override getRoute(legacy = false) {
        const nextTopic = _.first(this.topics());
        return nextTopic?.getRoute(legacy);       
    }
}