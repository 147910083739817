import { CommunityWidgetModel } from 'components/community/widgets/base/base';
import { Disposable } from 'interfaces/disposable';
import { ObservableArray } from 'knockout';
import { apiTopicNotificationData } from 'models/apiTopicNotificationData';
import { JSONWCCAttachment } from 'models/attachments/attachment';
import CommunityPage, { JSONCommunityPage } from 'models/community/page';
import { JSONCommunityWidgetTemplate } from 'models/community/widgetTemplate';
import { JSONTag } from 'models/tag';
import { JSONTopicThread } from 'models/topicThread';
import { JSONVideoPresentationEvent } from 'models/videoPresentationEvent';

export class AppEvents {
    threadApproved = new Event<[string]>();
    threadUnapproved = new Event<[string]>();
    threadDeleted = new Event<[topicId: string, postedById: string, threadId: string]>();
    threadTranslationIsRemoved = new Event<[string]>();
    threadTranslationIsChanged = new Event<[string, string]>();
    threadReportedAsAbuse = new Event<[string, boolean]>();
    threadClearedFromAbuseReport = new Event<[string]>();

    commentAdded = new Event<[topicId: string, comment: JSONTopicThread]>();
    commentUpdated = new Event<[topicId: string, commentId: string, comment: JSONTopicThread]>();
    commentDeleted = new Event<[topicId: string, commentId: string]>();
    commentPinnedStateChanged = new Event<[topicId: string, commentId: string, isPinned: boolean]>();
    commentNotificationsRemoved = new Event<[topicId: string, commentId: string]>();
    commentsNotificationsRemoved = new Event<[topicId: string]>();
    discussionCommentsNotificationsRemoved = new Event<[topicsData: Array<apiTopicNotificationData>]>();
    commentResponseIsMandatory = new Event<[topicId: string, commentId: string, isMandatory: boolean]>();

    replyAdded = new Event<[topicId: string, commentId: string, reply: JSONTopicThread]>();
    replyUpdated = new Event<[topicId: string, commentId: string, replyId: string, reply: JSONTopicThread]>();
    replyDeleted = new Event<[topicId: string, commentId: string, replyId: string]>();

    tagUpdated = new Event<[string, JSONTag]>();
    tagVisibilityUpdated = new Event<[string, string | undefined, number]>();

    threadTagAdded = new Event<[string, string, JSONTag]>();
    threadTagRemoved = new Event<[string, string]>();

    participantTagAdded = new Event<[string, string, JSONTag]>();
    participantTagRemoved = new Event<[string, string]>();

    communityDomainChanged = new Event<[]>();
    communityPageAdded = new Event<[page: CommunityPage]>();
    communityPageUpdated = new Event<[pageId: string, data: JSONCommunityPage]>();
    communityPageContentUpdated = new Event<[pageId: string, data: JSONCommunityPage]>();
    communityPageDeleted = new Event<[pageId: string]>();
    communityPagePublished = new Event<[pageId: string]>();
    communityPageEditRequested = new Event<[page: CommunityPage]>();
    communityPagesImported = new Event<[]>();
    communityPagesPublished = new Event<[]>();

    communityAttachmentAdded = new Event<[any]>();
    communityAttachmentDeleted = new Event<[string]>();

    communityWidgetSettingsAreChanged = new Event<[widget: any]>();
    communityWidgetSettingsAreShown = new Event<[widget: CommunityWidgetModel]>();
    communityWidgetSettingsAreHidden = new Event<[widget: CommunityWidgetModel]>();

    communityWidgetTemplateAdded = new Event<[template: JSONCommunityWidgetTemplate]>();
    communityWidgetTemplateUpdated = new Event<[templateId: string, template: JSONCommunityWidgetTemplate]>();
    communityWidgetTemplateRemoved = new Event<[templateId: string]>();

    personStatusChanged = new Event<[string, number]>();
    personWatchingVideo = new Event<[string, number]>();
    personAgreedToTerms = new Event<[string]>();
    personAvatarChanged = new Event<[avatarURL: string]>();
    personChanged = new Event<[]>();

    topicIsAnswered = new Event<[string]>();
    topicIsAvailable = new Event<[string]>();
    topicChangeRequested = new Event<[topicId: string]>();

    topicAttachmentAdded = new Event<[topicId: string, attachment: JSONWCCAttachment]>();
    topicAttachmentUpdated = new Event<[topicId: string, attachment: JSONWCCAttachment]>();
    topicAttachmentRemoved = new Event<[topicId: string, attachmentId: string]>();
    
    ideationStageChanged = new Event<[string, number]>();    

    highlightAdded = new Event<[string, any]>();

    mobileEditorRequested = new Event<[]>();

    deletingThread = new Event<[string, string]>();
    failedToDeleteThread = new Event<[string, string]>();

    videoPresentationEvent = new Event<[string, JSONVideoPresentationEvent]>();
}

type EventHandler<T extends Array<unknown>> = (...args: [...T]) => void

class Event<T extends Array<unknown>> {
    private handlers: ObservableArray<EventHandler<T>> = ko.observableArray();

    trigger(...args: [...T]) {
        this.handlers().forEach(func => func(...args));
    }

    on(func: EventHandler<T>): Disposable {
        this.handlers.push(func);

        return { dispose: this.off.bind(this, func) };
    }

    off(func: EventHandler<T>) {
        this.handlers.remove((item: EventHandler<T>) => item === func);
    }
}

const events = new AppEvents();

export default events;