import { Disposable } from "interfaces/disposable";
import signalRManager from 'managers/signalR/core/instance';

const Disposables = require('managers/disposables');

const { isStaging } = settings;

type messageFilter = (str: string) => boolean

export class BaseService {
    private _messageFilters: Array<messageFilter> = [name => name.toLowerCase() != 'topicmemberisworking' || !isStaging]
    private _disposables: any = new Disposables()

    signalRSubscription(group: string) {
        return {
            subscribe: (id: string) => this._register(signalRManager.subscribe(group, id))
        }
    }

    signalREvent(name: string) {
        return {
            on: (action: (...args: Array<any>) => void) => this._register(signalRManager.on(name, action))
        }
    }

    signalRMessage(name: string) {
        return {
            send: async (...args: Array<any>) => {
                if (this._messageFilters.every(func => func(name)))
                    await signalRManager.sendMessage(name, ...args);
            }
        };
    }
    
    dispose() {
        this._disposables.dispose();
    }

    _register(disposable: Disposable) {
        this._disposables.register(disposable);

        return disposable;
    }

    _toDeferred<T>(promise: Promise<T>): JQueryDeferred<T> {
        return $.Deferred(dfd => promise.then(dfd.resolve, dfd.reject));
    }
}